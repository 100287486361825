import { alpha, lighten, Theme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';

import { colors } from '../../../styles';
import { important } from '../../../utils';
import { HeaderProps } from '../header.types';
import { isDarkTheme } from '../header.utils';

export const useStyles = makeStyles<
  Theme,
  Pick<HeaderProps<unknown, unknown>, 'headerVariant'>
>(
  theme => ({
    submenuButtonComponent: {
      backgroundColor: important('transparent'),
    },
    submenuWrapper: ({ headerVariant }) => ({
      background: isDarkTheme(headerVariant)
        ? alpha(
            theme.customComponents.header.headerWrapper.dark.backgroundColor,
            0.2,
          )
        : colors.whiteSmokeGrey,
      borderStyle: 'solid',
      borderWidth: `1px 0px`,
      borderColor: isDarkTheme(headerVariant)
        ? 'transparent'
        : alpha(colors.black, 0.2),
      marginTop: theme.spacing(2),

      [theme.breakpoints.up('sm')]: {
        marginTop: theme.spacing(3),
      },
    }),
    submenuToolbar: {
      minHeight: theme.spacing(0),
    },
    submenuTabs: ({ headerVariant }) => ({
      'margin': theme.spacing(0),
      'minHeight': theme.spacing(0),
      'alignItems': 'center',

      '& .MuiTabs-scroller': {
        height: '100%',
      },

      '& .MuiTabs-indicator': {
        backgroundColor: isDarkTheme(headerVariant)
          ? colors.darkGray
          : alpha(colors.black, 0.4),
      },

      '& .MuiTabs-flexContainer': {
        gap: theme.spacing(2),
      },
    }),
    submenuTab: {
      opacity: 1,
      minWidth: 'auto',
      padding: theme.spacing(0.75),
      minHeight: theme.spacing(0),
    },
    submenuTabLabel: ({ headerVariant }) => ({
      color: isDarkTheme(headerVariant)
        ? lighten(colors.white, 0.3)
        : colors.black,
      fontWeight: theme.typography.fontWeightBold,
      textTransform: 'capitalize',
    }),
    submenuTabLabelActive: ({ headerVariant }) => ({
      color: isDarkTheme(headerVariant)
        ? colors.darkGray
        : alpha(colors.black, 0.4),
    }),
  }),
  { name: 'UIHeaderStyles' },
);
