import { create } from 'zustand';

import { Pricing } from '@/services/rest';

type PricingPublicData = Pricing.PricingPublicData;
type PricingPublicParams = Pricing.PricingPublicParams;

type State = {
  pricing?: PricingPublicData;
  loading: boolean;
  error?: string;
};

type Actions = {
  fetch: (params: PricingPublicParams, locale?: string) => Promise<void>;
  hydrate: (pricing: PricingPublicData) => void;
  clear: () => void;
};

export const initialPricingState = {
  loading: false,
  pricing: undefined,
  error: undefined,
};

export const usePricingStore = create<State & Actions>()(set => ({
  pricing: initialPricingState.pricing,
  loading: initialPricingState.loading,
  error: initialPricingState.error,
  hydrate: pricing => set({ pricing }),
  clear: () => set({ pricing: undefined }),
  fetch: async (params, locale) => {
    const defaultError = 'Could not fetch pricing';
    set({ loading: true });
    try {
      const { data, ok, error } = await Pricing.getPublic(params, locale);

      if (error) throw new Error(error as string);
      if (!ok) throw new Error(defaultError);

      set({ pricing: data, loading: false });
    } catch (error) {
      set({
        error: (error as Error)?.message || defaultError,
        loading: false,
      });
    }
  },
}));
