import { DEFAULT_LOCALE } from '@wr/web-shared';
import { useRouter } from 'next/router';
import { useMemo } from 'react';

import { useAppContext } from '@/context';
import { getIntlMessages, GetIntlMessagesResult } from '@/utils/intl/messages';

export function useIntlMessages():
  | GetIntlMessagesResult
  | Record<string, never> {
  const { locale = DEFAULT_LOCALE } = useRouter();

  const { sendCountry, receiveCountry, sendCountries, intl } = useAppContext();

  const receiveCountryCode = receiveCountry?.iso2?.toUpperCase?.();
  const sendCountryCode = sendCountry?.iso2?.toUpperCase?.();

  return useMemo(
    () => {
      if (!sendCountry || !receiveCountry) {
        return {};
      }
      const currentDate = new Date();

      return {
        ...getIntlMessages({
          sendCountry,
          sendCountries,
          receiveCountry,
          payoutMethodsLabels: intl.payoutMethodsLabelsById,
          countryArticlesAndPrepositions: intl.countryArticlesAndPrepositions,
          currentDate,
          locale,
          corridorPayoutMethodsIds: [],
        }),
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [receiveCountryCode, sendCountryCode, locale],
  );
}
