import clsx from 'clsx';
import { FC } from 'react';

import { Header } from '@/components/header';
import { buildContentfulSidekickAttributes } from '@/utils';

import { Footer } from '../footer';
import { useStyles } from './layout.styles';
import { LayoutProps } from './layout.types';

const Layout: FC<LayoutProps> = ({
  sys,
  __typename,
  children,
  name,
  className,
  header,
  footer,
  breadcrumbs,
  blogTags,
  languageFromCookie,
}) => {
  const classes = useStyles();

  return (
    <>
      {header && (
        <Header
          {...header}
          languageFromCookie={languageFromCookie}
          blogTags={blogTags}
        />
      )}
      <main
        data-testid={name}
        className={clsx(className, classes.container)}
        {...buildContentfulSidekickAttributes(sys?.id, __typename, name)}
      >
        {children}
      </main>

      {footer && <Footer {...footer} breadcrumbs={breadcrumbs || null} />}
    </>
  );
};

export default Layout;
