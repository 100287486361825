import { AppContextShared } from '@wr/web-ui';
import React, { PropsWithChildren, useContext } from 'react';

import { AppContext } from './app.hooks';
import { AppContextType } from './app.types';

export const AppContextProvider = ({
  children,
  ...props
}: PropsWithChildren<Partial<AppContextType>>) => {
  const sharedContext = useContext(AppContextShared);
  return (
    <AppContext.Provider
      value={{
        ...sharedContext,
        sendCountry: sharedContext.sendCountry || null,
        receiveCountry: sharedContext.receiveCountry || null,
        placeholder: props.placeholder || '',
        ...props, //Incase any other prop is wrongfully passed it's still available
      }}
    >
      {children}
    </AppContext.Provider>
  );
};
