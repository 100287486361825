import { GetIntlMessagesConfig } from './messages';

export const getCurrenciesCodesAndName = (
  country:
    | GetIntlMessagesConfig['sendCountry']
    | GetIntlMessagesConfig['receiveCountry'],
  countries:
    | GetIntlMessagesConfig['sendCountries']
    | GetIntlMessagesConfig['receiveCountries'],
): [currenciesCodes: string[], currenciesNames: string[]] => {
  if (!countries) return [[], []];

  // countries return duplicated objects because country can have multiple currencies
  const _countries = countries.filter(_country => {
    return _country.iso2 === country.iso2;
  });

  const currenciesCodes: string[] = [];
  const currenciesNames: string[] = [];

  for (const country of _countries) {
    if (country.currencyDisplay) {
      currenciesNames.push(country.currencyDisplay);
    }

    if (country.currency) {
      currenciesNames.push(country.currency);
    }
  }

  return [currenciesCodes, currenciesNames];
};
