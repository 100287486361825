import { create } from 'zustand';

import { FaqCountry, FaqTopic } from './faq.types';
import { filterByCountry } from './faq.utils';

export * from './faq.types';
export * from './faq.utils';

export type FaqStoreState = {
  _allFaqTopics?: FaqTopic[];
  faqTopics?: FaqTopic[];
  faqCountries?: FaqCountry[];
  searchTerm?: string;
  selectedCountry?: string;
};

type Actions = {
  setSearchTerm: (searchTerm: string) => void;
  clearFilters: () => void;
  setSelectedCountry: (selectedCountry: string) => void;
};

export const initialFaqState = {
  _allFaqTopics: undefined,
  faqTopics: undefined,
  faqCountries: undefined,
  searchTerm: undefined,
  selectedCountry: undefined,
};

export const useFaqsStore = create<FaqStoreState & Actions>()(set => ({
  _allFaqTopics: initialFaqState._allFaqTopics,
  faqTopics: initialFaqState.faqTopics,
  faqCountries: initialFaqState.faqCountries,
  searchTerm: initialFaqState.searchTerm,
  selectedCountry: initialFaqState.selectedCountry,
  setSearchTerm: async (searchTerm: string) => {
    if (!searchTerm) {
      set({
        searchTerm: undefined,
      });

      return;
    }

    set({
      searchTerm,
      selectedCountry: undefined,
    });
  },
  clearFilters: () => {
    set(state => ({
      searchTerm: undefined,
      selectedCountry: undefined,
      faqTopics: state._allFaqTopics,
      faqSearchResults: undefined,
    }));
  },
  setSelectedCountry: (selectedCountry: string) => {
    if (!selectedCountry) {
      set(state => ({
        selectedCountry: undefined,
        faqTopics: state._allFaqTopics,
      }));

      return;
    }

    set(state => {
      const faqTopics = filterByCountry(selectedCountry, state._allFaqTopics);
      return {
        selectedCountry,
        searchTerm: undefined,
        faqTopics,
      };
    });
  },
}));
