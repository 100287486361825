import { contentfulSchema } from '@wr/web-shared';

import { FaqStoreState } from './faq.store';
import { Faq, FaqCountry, FaqTopic } from './faq.types';

export const filterByCountry = (
  selectedCountry?: string,
  faqTopics?: FaqTopic[],
) => {
  if (!selectedCountry || !faqTopics) return faqTopics;

  return faqTopics.map(topic => {
    const faqs = topic.faqs.filter(faq =>
      faq.countries.some(country => country.code === selectedCountry),
    );
    return {
      ...topic,
      faqs,
    };
  });
};

export const hydrateFaqState = ({
  faqTopicsCollection,
  receiveCountry,
}: {
  faqTopicsCollection?: {
    items: (Omit<contentfulSchema.FaqTopicFragment, 'faqItems'> & {
      faqItems: {
        items: (Omit<contentfulSchema.FaqItemFragment, 'countries'> & {
          countries?: {
            items: contentfulSchema.CountryFragment[];
          };
        })[];
      };
    })[];
  };
  receiveCountry?: string;
}): FaqStoreState => {
  const faqCountries: FaqCountry[] = [];
  const faqTopics: FaqTopic[] = [];

  faqTopicsCollection?.items.forEach(topic => {
    const faqs: Faq[] = [];
    if (topic.faqItems)
      topic.faqItems.items.forEach(faq => {
        const countries: FaqCountry[] = [];

        faq.countries?.items.forEach(country => {
          const itemCountry = {
            id: country.sys.id,
            name: country.name,
            code: country.code,
            prepositionFrom: country.prepositionFrom,
            prepositionTo: country.prepositionTo,
            prepositionIn: country.prepositionIn,
          };
          countries.push(itemCountry);
          if (!faqCountries.find(country => country.id === itemCountry.id))
            faqCountries.push(itemCountry);
        });

        faqs.push({
          id: faq.sys.id,
          name: faq.name,
          title: faq.title,
          body: faq.body,
          countries,
        });
      });

    faqTopics.push({
      id: topic.sys.id,
      name: topic.name,
      title: topic.title,
      description: topic.description,
      subtitle: topic.subtitle,
      icon: topic.icon,
      faqs,
    });
  });

  return {
    _allFaqTopics: faqTopics,
    faqTopics: receiveCountry
      ? filterByCountry(receiveCountry, faqTopics)
      : faqTopics,
    faqCountries,
    searchTerm: undefined,
    selectedCountry: receiveCountry,
  };
};
